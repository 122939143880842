html {
  /* 1rem = 10px */
  font-size: 62.5%;
  height: -webkit-fill-available;
}
body {
  font-size: 1.6rem;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
#app {
  color: $text;
}
