

















































































@import '~@/styles/import';

.info-box {
  max-width: calc(100% - 3.2rem);
}

