






































.backdrop-blur {
  &-md {
    backdrop-filter: blur(4px);
  }
}
