


















.left-1100 {
  left: 1100px;
  max-width: clamp(250px, calc(100vw - 1100px),700px)
}
