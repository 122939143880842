




















































.back-link {
  grid-template-columns: min-content auto;
  grid-column-gap: 1rem;
}

.legal-links {
  @apply grid;
  grid-template-columns: min-content 1rem min-content;
  white-space: nowrap;

  @media (max-width: 1279px) {
    span {
      display: none;
    }
    margin-top: -10px;
    grid-template-columns: min-content;
    grid-auto-rows: 1fr;
  }
  @media (min-width: 1280px) {

  }
}

