
































































































































































































































































































































































































































































































































































































































































































































































@import '~@/styles/import';

@mixin first-day-of-week() {
  $weekdays: 'mo','tu','we','th','fr','sa','su';

  @each $weekday in $weekdays {
    $offset: 7 - index($weekdays, $weekday);

    @if $offset >= 7 {
      $offset: $offset - 7;
    }

    &.first-day-of-week {
      &--#{$weekday} {

        @for $i from 1 through length($weekdays) {
          $itWeekday: nth($weekdays, $i);
          $start: $i + $offset + 1;

          @if $start > 7 {
            $start: $start - 7;
          }

          .weekday:nth-of-type(#{$i}) {
            grid-column-start: $start;
          }
        }

        .day {
          @for $i from 1 through length($weekdays) {
            $itWeekday: nth($weekdays, $i);
            $start: $i + $offset + 1;

            @if $start > 7 {
              $start: $start - 7;
            }

            &.#{$itWeekday} {
              grid-column-start: $start;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  @apply hidden;
  // display: none;
  --border-color: $grayLight;
  --font-size: 1.2rem;
  --line-height: 1.5;
  min-width: 220px;
  max-width: 600px;
  font-size: var(--font-size);
  line-height: var(--line-height);

  @supports(display: grid) {
    @apply grid select-none;
    grid-template-columns: 1fr;
    // display: grid;
    // user-select: none;

    .button {
      padding: 10px 14px;
      font-size: 120%;
      line-height: 90%;
      border: 1px solid lightgray;
      border: 1px solid var(--border-color);
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s ease, box-shadow 0.1s ease;

      &:hover {
        background-color: #efefef;
      }

      &:active {
        box-shadow: inset 0px 0px 10px 0px #ccc;
      }

      &__wrapper {
        display: grid;
        grid-template-columns: min-content;

        &--right {
          justify-content: right;
        }
      }

      &--next {
        float: right;
      }

      &--image {
        border: none;
        padding: 0;
        width: 48px;
        height: 48px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        &:hover {
          background-color: transparent;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.calendar__container {
  grid-column-start: span 2;

  overflow: hidden;
  user-select: none;
}

.calendar {
  @apply grid;
  // display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--months-margin);
  grid-row-gap: 35px;

  &--multi-line {
    grid-template-columns: 1fr;
  }

  &--border {
      .month {
          .day {
              box-shadow: 0 0 0 1px white;
              box-shadow: 0 0 0 1px var(--border-color);
              &.disabled {
                  box-shadow: 0 0 0 1px var(--unavailable-color);
              }
              &.departure-only {
                  &:before {
                      box-shadow: 1px 1px 0 0px var(--unavailable-color);
                  }
              }
              &.arrival-only {
                  &:before {
                      box-shadow: -1px -1px 0 0px var(--unavailable-color);
                  }
              }
          }
      }
  }

  .month {
    @apply grid p-1 box-border z-0;
    // display: grid;
    // padding: 1px;
    // box-sizing: border-box;
    grid-template-columns: repeat(7, 1fr);
    max-width: 100%;
    grid-gap: 1px;
    grid-auto-rows: 35px;
    grid-template-rows: 35px repeat(6, max-content);

    grid-row-gap: 7px;
    grid-column-gap: 7px;

    &--weekdays {
      grid-template-rows: 50px;
      position: sticky;
      top: 0;

      @apply z-20;
      .weekdays {
        @apply bg-grayVeryLight;

        .weekday {
          @apply bg-grayVeryLight;
        }
      }
    }

    .month-name {
      @apply font-semibold text-14 pb-16;
      grid-column-start: span 7;
    }

    .weekdays {
      @apply grid;
      grid-column-start: 1;
      grid-column-end: span 7;
      grid-template-columns: repeat(7, 1fr);
      // margin-bottom: 5px;
      // height: 35px;
    }

    .weekday {
      grid-row-start: 2;
      margin-left: -1px;
      margin-right: -1px;
      margin-bottom: -1px;

      @apply text-12 py-16 text-center text-gray font-medium border-grayLight border-t border-b;
    }

    .day {
      // line-height: 36px;
      // position: relative;
      // border: 2px solid var(--border-color);
      // border-radius: 3px;
      // cursor: pointer;

      @apply cursor-pointer relative text-gray text-12 font-medium text-center border-2 rounded-sm border-grayLight flex items-center justify-center;

      --border-width: 2px;
      padding-bottom: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
      padding-top: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
      //display: grid;
      //grid-auto-rows: 1fr;
      //padding-bottom: calc(50% - ((var(--font-size) * var(--line-height) * 2) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
      //padding-top: calc(50% - ((var(--font-size) * var(--line-height) * 2) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
      padding-bottom: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
      padding-top: calc(50% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width

      &:not(.has-price) {
        .day--price {
          display: none;
        }
      }

      &.has-price {
        @media (min-width: 446px) and (max-width: 767px) {
          padding-bottom: calc(60% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
          padding-top: calc(40% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        }
        @media (min-width: 980px) {
          padding-bottom: calc(55% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
          padding-top: calc(45% - ((var(--font-size) * var(--line-height)) / 2) - var(--border-width)); // 1.2rem = font-size; 1.5 = line-height; 2px = border-width
        }

        .day--price {
          @apply text-10 text-grayBlue;
          position: absolute !important;
          margin-left: auto;
          margin-right: auto;
          bottom: 10%;
          display: none;
          @media (min-width: 446px) and (max-width: 767px) {
            display: block;
          }
          @media (min-width: 980px) {
            display: block;
          }
          @media (min-width: 580px) and (max-width: 767px) {
            bottom: 25%;
          }
          @media (min-width: 1024px) and (max-width: 1100px) {
            bottom: 15%;
          }
          @media (min-width: 1101px) {
            bottom: 20%;
          }
        }
      }

      &.disabled, &.past {
        .day--price {
          display: none;
        }
      }

      &.selected {
        .day--price {
          @apply text-dark;
        }

        &--start, &--end {
          .day--price {
            @apply text-white;
          }
        }
      }

      .day--text {
        @apply text-12 z-50 block relative;
        // z-index: 999;
        // display: block;
        // position: relative;
      }

      &.selected {
        // background-color: var(--primary-color);
        // position: relative;
        @apply relative border-transparent text-dark;

        &:before {
          content: ' ';
          @apply absolute block bg-primary opacity-40 border-2 rounded-0 border-primary;
          left: -5.5px;
          top: -2px;
          bottom: -2px;
          right: -5.5px;
          // display: block;
          // position: absolute;
          // background-color: #CCCDCF;

        }

        &--start, &--end:not(.selected--end--unconfirmed) {
          @apply bg-primary text-white border-2 border-primary rounded-0;
          // background-color: #383F45;
          // color: white;

          &:before {
            content: ' ';
            @apply block absolute;
            left: -5.5px;
            top: -2px;
            bottom: -2px;
            right: -5.5px;
          }

          &.arrival-only, &.departure-only {
            &:before {
              background: none !important;
            }
          }
        }

        &--start {
          @apply rounded-l-sm;
          &:after {
            content: '';
            top: -2px;
            bottom: -2px;
            right: -5.5px;
            width: 5.5px;
            @apply bg-primary absolute;
          }
          &:before {
            @apply rounded-l-sm left-0;
            // border-top-left-radius: 3px;
            // border-bottom-left-radius: 3px;
            // left: 0px;
          }

          &.selected--end {
            @apply rounded-r-sm rounded-l-sm;
            &:before {
              @apply right-0 left-0 rounded-r-sm rounded-l-sm;
            }
            &:after {
              display: none;
            }
          }
        }

        &--end:not(.selected--start) {
          @apply rounded-r-sm;
          &:after {
            content: '';
            top: -2px;
            bottom: -2px;
            left: -5.5px;
            width: 5.5px;
            @apply bg-primary absolute;
          }
          &:before {
            @apply rounded-r-sm right-0;
            // border-top-right-radius: 3px;
            // border-bottom-right-radius: 3px;
            // right: 0px;
          }

          &.selected--end--unconfirmed {
            @apply text-dark;
            background-color: transparent!important;

            &:before {
              @apply bg-primary opacity-40 border-2 border-primary;

              background: var(--color-primary);
              top: -2px;
              bottom: -2px;
              right: -3.5px;
            }

            &:after {
              @apply opacity-0;
            }
          }
        }
      }

      &.past {
        @apply cursor-not-allowed border-transparent text-grayBlue bg-transparent;
      }

      &.disabled {
        background-color: $grayLight;
        background-color: var(--unavailable-color);
        //@apply bg-grayLight pointer-events-none;
        @apply cursor-not-allowed;
        color: $grayBlue;
        color: var(--unavailable-text-color);
      }
      &.departure-only {
        &:before {
          content: ' ';
          @apply absolute top-0 left-0 bottom-0 right-0;
          background: linear-gradient(to right bottom, transparent 50%, $grayLight 50%);
          background: linear-gradient(to right bottom, transparent 50%, var(--unavailable-color) 50%);
        }

        &.no-click {
          //@apply pointer-events-none;
          @apply cursor-not-allowed;
        }
      }
      &.arrival-only {
        &:before {
          content: ' ';
          @apply absolute top-0 left-0 bottom-0 right-0;
          background: linear-gradient(to left top, transparent 50%, $grayLight 50%);
          background: linear-gradient(to left top, transparent 50%, var(--unavailable-color) 50%);
        }
      }
    }

    @include first-day-of-week();
  }

  &:not(.calendar--start-date-selected), &.calendar--start-date-selected.calendar--end-date-selected {
    .month {
      .day {
        &:not(.selected) {
          &.restriction-arrival-closed {
            @apply border-solid cursor-not-allowed;
            color: $grayBlue;
            color: var(--unavailable-text-color);
          }
          &.available-not-bookable-due-to-minlos {
            color: #039be5;
            background: #1bc176;
          }
        }
      }
    }
  }
  &.calendar--start-date-selected:not(.calendar--end-date-selected) {
    .month {
      .day {
        &:not(.selected) {
          &.restriction-departure-closed {
            @apply border-solid cursor-not-allowed;
            color: $grayBlue;
            color: var(--unavailable-text-color);
          }
        }
      }
    }
  }

}
