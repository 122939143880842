






































































@import '~@/styles/import';

.logo {
  max-height: 8.5rem;
  height: auto;
  width: auto;
  max-width: 100%;
}

nav {
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 1rem;
}
