@charset "utf-8";

@import 'colors';
@import 'mixins/aspect-ratio';
@import 'layout';
@import 'typography';
@import 'tooltip';
@import 'inputs';

*:focus {
  outline: none;
}

body {
  button {
    &:focus {
      @apply outline-none;
    }
  }
}

.cc-nb-okagree, .cc-cp-foot-save {
  background-color: #1bc176!important;
}

.cc-nb-reject {
  //background-color: #d54c4d!important;
  background-color: #394045!important;
}

.cc-nb-changep {
  background-color: #a1abb3!important;
  color: #fff!important;
}

.cc-nb-main-container {
  border: 1px solid #a1abb3!important;
  background-color: #fafafa!important;
}

.cc-nb-okagree, .cc-cp-foot-save, .cc-nb-reject, .cc-nb-changep {
  border-radius: 2rem!important;
  left: 20px;
}

.cc-nb-buttons-container {
  display: grid!important;
  grid-auto-columns: auto;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  grid-column-gap: 1.8rem;
  grid-row-gap: 1.2rem;
  width: auto;

  @media (min-width: 1024px) {
    grid-auto-flow: column;
  }
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 50%;
  right: 0px;
  border-top: 2px solid;
  border-color: $primary;

  transform:rotate(-5deg);
}
