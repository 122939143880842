

























































































































































































































































































































































































@import '~@/styles/import';

.logo {
  max-height: 8.5rem;
  height: auto;
  width: auto;
  max-width: 100%;
}

nav {
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 1rem;
}

.nav-list {
  li {
    a {
      @apply relative -ml-24 pl-24 justify-center py-16;
      //@apply flex flex-col;
      @apply grid;
      grid-template-columns: 1fr min-content;
      grid-auto-flow: row;
      grid-auto-rows: min-content;

      @media (min-width: 768px) {
        @apply -ml-48 pl-48;
      }

      span {
        @apply hidden;
      }

      &[disabled] {
        @apply pointer-events-none;
      }

      h3 {
        @apply text-16 text-gray font-semibold;
      }

      .check {
        opacity: 0;
      }

      &.complete {
        h3 {
          @apply text-primary;
        }

        .check {
          opacity: 1;
        }

        span {
          @apply block;
        }
      }

      &.active {
        h3 {
          @apply text-16 text-dark font-semibold;
        }

        @apply pl-36;

        @media (min-width: 768px) {
          @apply pl-56;
        }

        .text-selected {
          @apply text-dark;
        }

        span {
          @apply block;
          &:not(.text-gray) {
            @apply text-dark;
          }
        }

        &:before {
          content: '';
          @apply w-4 h-full absolute left-0 top-0 bg-primary rounded-2;
        }
      }
    }
  }
}

.nav-footer-links {
  @apply grid bottom-0 left-24 right-24 pt-24 mt-24 -mx-24 px-24 overflow-hidden text-12 text-gray border-t-1 border-grayLight;

  @media (min-width: 768px) {
    @apply absolute pb-24;
  }

  line-height: 1.6rem;

  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 0.8rem;

  &-legal {
    @apply grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: repeat(5, min-content);
  }

  a {
    @apply whitespace-no-wrap;
    &:hover {
      @apply underline;
    }
  }
}

.language-switcher {
  @apply relative -left-4 -top-1 bg-transparent border-0 text-12 px-0 text-gray cursor-pointer font-bold;

  width: 100%;
  width: min-content;
}

.nav-list-wrapper {
  @apply hidden;

  grid-template-rows: 1fr max-content;

  @apply overflow-auto;

  @media (max-width: 767px) {
    &.block {
      @apply top-64 fixed bg-white bottom-0 left-0 right-0 z-80 px-24 pb-24 pt-8 grid;
    }
  }

  @media (min-width: 768px) {
    @apply grid;
  }
}
