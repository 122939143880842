



























































































































































































































































































.tooltip {
  max-width: 300px!important;
}

.modal {
  iframe {
    height: calc(100% - 5.0rem);
  }
}

