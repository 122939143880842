





























































































@import '~@/styles/import';

hr {
  border-color: $grayBlue!important;
}

.text-rtl {
  text-align: right;
  direction: rtl;
}
