.input {
  @apply border-grayBlue border-2 w-full block py-18 px-16 text-dark rounded-4;

  &.invalid {
    @apply border-error;
  }
}

.input-label {
  &.invalid {
    @apply text-error;
  }
}

// TEXTAREA

.textarea {
  @apply border-grayBlue border-2 w-full block py-18 px-16 text-dark rounded-4;

  &.invalid {
    @apply border-error;
  }
}

.textarea-label {
  &.invalid {
    @apply text-error;
  }
}

// CHECKBOX

.checkbox-label {
  @apply block relative cursor-pointer mb-24;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label input:checked ~ .checkbox-checked {
  @apply flex;
}

.checkbox-checked {
  @apply hidden absolute h-20 w-20 items-center justify-center bg-primary rounded-4 top-2 left-0;
  border: 2px solid #308a82;
}

.checkbox-unchecked {
  @apply absolute left-0 top-2 h-20 w-20 border-grayBlue border-2 rounded-4 inline-block;
}

.checkbox-title {
  @apply  ml-32 text-dark text-16 inline-block;

}

// SELECT

$vs-colors: (
        lightest: $light,
        light: $gray,
        dark: $text,
        darkest: $text,
) !default;

//  Global Component Variables
$vs-component-line-height: inherit !default;
$vs-component-placeholder-color: inherit !default;

//  Active State
$vs-state-active-bg: $primary !default;
$vs-state-active-color: #fff !default;

//  Disabled State
$vs-state-disabled-bg: rgb(248, 248, 248) !default;
$vs-state-disabled-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-controls-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-cursor: not-allowed !default;

//  Borders
$vs-border-width: 0.2rem !default;
$vs-border-style: solid !default;
$vs-border-radius: 0.4rem !default;
$vs-border-color: $grayBlue !default;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: map_get($vs-colors, 'light') !default;
$vs-controls-size: 1 !default;
$vs-controls-deselect-text-shadow: 0 1px 0 #fff;

//  Selected
$vs-selected-bg: #f0f0f0 !default;
$vs-selected-border-color: $vs-border-color !default;
$vs-selected-border-style: $vs-border-style !default;
$vs-selected-border-width: $vs-border-width !default;

//  Dropdown
$vs-dropdown-z-index: 1000 !default;
$vs-dropdown-min-width: 160px !default;
$vs-dropdown-max-height: 350px !default;
$vs-dropdown-box-shadow: 0px 3px 6px 0px map_get($vs-colors, 'darkest') !default;
$vs-dropdown-bg: #fff !default;

@import 'vue-select/src/scss/vue-select.scss';

// 16/24

.v-select {
  background: $light;

  &:focus-within {
    .vs__dropdown-toggle {
      @apply border-primary;
    }
  }
}

.vs__selected-options, .vs__selected {
  line-height: 24px;
  font-size: 16px;
  padding: 0;
  border: none;
  margin: 0;
}

.vs--open .vs__dropdown-toggle {
  @apply rounded-4;
}

.vs__selected-options {
  @apply m-0;
}

.vs__selected {
  @apply px-14 py-12;
}

.vs__dropdown-toggle {
  @apply p-0;
}

.vs__actions {
  @apply pr-18;
}

.vs__search, .vs__search:focus {
  @apply p-0 border-0 m-0 top-0 left-0 h-60 mx-14 py-12;
}

.vs--open .vs__selected {
  @apply top-6 left-0;
}

.vs__dropdown-menu {
  @apply p-0 border-0 mt-2;

  .vs__dropdown-option {
    @apply py-12 px-16;
  }
}
