



















.column2 {
  p {
    @apply mb-16;
  }

  img, iframe {
    @apply border-grayLight border rounded-8 mb-16;

    &.rounded-0 {
      @apply rounded-0;
    }
  }

  ul {
    @apply mt-16 ml-0;
    list-style-type: '✓';
    list-style-position: outside;
    list-style-image: none;
    list-style: none;

    li {
      @apply pl-20 mb-16;

      &:before {
        @apply text-primary font-semibold inline-block text-16;
        content: '✓';
        width: 2rem;
        margin-left: -2rem;
      }
    }
  }
}
