







































































































































































































































































































.button-backdrop {
  @apply fixed bottom-0 left-0 h-128 z-20;
  width: calc(100% + 2 * 64px);
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  @media (min-width: 768px) {
    @apply fixed left-460;
    width: calc(100% - 460px - 64px + 2 * 64px);
    margin-left: -64px;
    max-width: calc(57.2rem + 2 * 6.4rem);
  }
}
